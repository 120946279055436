// import axios from 'axios';
//
// let updateCartCancelToken = undefined;
//
// export function fastUpdateCart() {
//     if (updateCartCancelToken) {
//         updateCartCancelToken.cancel();
//     }
//     updateCartCancelToken = axios.CancelToken.source();
//
//     const url = '/it/cart/';
//     const formElement = document.getElementById('sylius_cart');
//     const formData = new FormData(formElement);
//     formData.set('_method', "PATCH");
//
//     axios({
//         method: "post",
//         url: url,
//         data: formData,
//         config: { headers: {'Content-Type': 'multipart/form-data' }},
//         cancelToken: updateCartCancelToken.token,
//     })
//     .then(function (response) {
//         if (response.status != 200) {
//             throw Error('errore');
//         } else {
//             let newHtml = new DOMParser().parseFromString(response.data, "text/html");
//             let totals = newHtml.getElementById('sylius_cart_totals');
//             if (totals) {
//                 document.getElementById('sylius_cart_totals').innerHTML = totals.innerHTML;
//             }
//
//             let itemTotals = newHtml.getElementsByClassName('sylius_cart_item_total');
//             [...itemTotals].forEach(itemTotal => {
//                 document.getElementById(itemTotal.id).innerHTML = itemTotal.innerHTML;
//             })
//
//         }
//     })
//     .catch(function (response) {
//         // if (axios.isCancel(error)) {
//         //     this.ajaxFunctions.getListLoading = true;
//         // }
//         console.log(response);
//     });
// }
// // global.fastUpdateCart = fastUpdateCart;
