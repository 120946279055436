import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import AOS from 'aos';

import '../../../../node_modules/swiper/swiper.min.css';
import '../../../../node_modules/swiper/modules/navigation/navigation.min.css';
import '../../../../node_modules/swiper/modules/pagination/pagination.min.css';
// import '../../../../node_modules/semantic-ui-css';
// import '/semantic.css';



import { fastUpdateCart } from './cart-updater';
/**
 * Italian translation for bootstrap-datepicker
 * Enrico Rubboli <rubboli@gmail.com>
 */
// ;(function($){
//   $.fn.datepicker.dates['it'] = {
//     days: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"],
//     daysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
//     daysMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do"],
//     months: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
//     monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
//     today: "Oggi",
//     clear: "Cancella",
//     weekStart: 1,
//     format: "dd/mm/yyyy"
//   };
// }(jQuery));


//
// $( "#birthday").datepicker({
//   startDate: new Date,
//   language:'it',
//   format: 'dd/mm/yyyy',
//
// });
Swiper.use([Navigation, Pagination, Autoplay]);

var galleryPage = new Swiper(".gallery-page", {
  slidesPerView: 3,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    600: {
      slidesPerView: 2.1,
      spaceBetween: 10
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    1300: {
      slidesPerView: 4,
      spaceBetween: 10
    },
  }
});


var galleryComposition = new Swiper(".gallery-composition", {
  slidesPerView: 3,
  spaceBetween: 20,

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    }
});


var galleryTopHome = new Swiper(".gallery-top-home", {
  slidesPerView: 1,
  spaceBetween: 20,
  autoplay: {
    delay: 4000,
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  }
});



var galleryGenericHome = new Swiper(".gallery-generic-home", {
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.1,
      spaceBetween: 10
    },
    // when window width is >= 480px

  }
});


var galleryNav = new Swiper(".gallery-nav", {
  slidesPerView: 2.5,
  visibilityFullFit: true,
  autoResize: false,
  breakpoints: {
    // when window width is >= 320px
    350: {
      slidesPerView: 2.5,
      spaceBetween:0
    },
    600: {
      slidesPerView: 2.5,
      spaceBetween: 0
    },
    850: {
      slidesPerView: 5,
      spaceBetween: 0
    },
    700: {
      slidesPerView: 4.1,
      spaceBetween: 0
    },

  }
});


var galleryProduct = new Swiper(".gallery-product", {
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  preventInteractionOnTransition: true,
  navigation: {
    nextEl: '.swiper-button-next.btn-product',
    prevEl: '.swiper-button-prev.btn-product',
  },
  pagination: {
  el: '.swiper-pagination',
  type: 'bullets',
},
});


var galleryProducts = new Swiper(".gallery-products", {
  slidesPerView: 1,
  spaceBetween: 20,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1000: {
      slidesPerView: 4,
      spaceBetween: 10
    },
  },
  navigation: {
    nextEl: '.swiper-button-next.btn-products',
    prevEl: '.swiper-button-prev.btn-products',
  },
});

var galleryHomeProducts = new Swiper(".gallery-home-products", {
  slidesPerView: 1,
  spaceBetween: 20,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 0
    },
    1500: {
      slidesPerView: 4,
      spaceBetween: 0
    },

  }

});

$("#open-modal-register").click(function () {

    $("#modal-login").modal("hide")
    $("#modal-register").modal("show")

  })


$("#add-qta").on('click', function () {
    let element = $(this).prev();
    let qta = element.val();
    let number =  parseInt(qta, 10);
    element.val(number + 1);
    // fastUpdateCart();
});

$("#remove-qta").on('click', function () {
    let element = $(this).next();
    let qta = element.val();
    let number = parseInt(qta, 10);
    if (number > 1) {
        element.val(number - 1);
        // fastUpdateCart();
    }
});

// $('.fast-cart--total').on('change', function() {
//     fastUpdateCart();
// });



$(".add-qta-list").click(function () {
    let idx = $(this).data('listed')
    let qta = $("#count-"+idx).val()
  let number =  parseInt(qta, 10);
  $("#count-"+idx).val(number+1)
})

$(".remove-qta-list").click(function () {
  let idx = $(this).data('listed')
  let qta = $("#count-"+idx).val()
  if(qta>"1"){
    let number =  parseInt(qta, 10);
    $("#count-"+idx).val(number+1)
  }
})

/*TODO unify methods for counter*/

$(".add-qta-list").click(function () {
  let idx = $(this).data('listed')
  let qta = $("#mob-count-"+idx).val()
  let number =  parseInt(qta, 10);
  $("#mob-count-"+idx).val(number+1)
})

$(".remove-qta-list").click(function () {
  let idx = $(this).data('listed')
  let qta = $("#mob-count-"+idx).val()
  if(qta>"1"){
    let number =  parseInt(qta, 10);
    $("#mob-count-"+idx).val(number+1)
  }
})




$('.collapse-btn').click(function(){
    $('.collapse-btn').find('span').text('+')
  $(this).find('span').text(function(i,old){
      return old=='+' ?  '-' : '+';
  });

  $(".collapse-btn").each(()=> {
    if($(this).attr('aria-expanded')==='true'){
      $('.collapse-btn').find('span').text('+')
    }

})
});


$("#burger-menu").click(function() {
  $(".menu-mob").show()
  $('body, html').css('overflow', 'hidden')
})

$(".close-menu").click(function() {
  $(".menu-mob").hide()
  $('body, html').css('overflow', 'auto')
})



$('#modal-login').on('hidden.bs.modal', function (e) {
  let login = $("#login-form").validate();
  login.resetForm();

})

$('#modal-register').on('hidden.bs.modal', function (e) {
  let register = $("#register-form").validate();
  register.resetForm();

})


/*fast cart*/


/*
* CLOSE FAST CARD
*/

const closeFastCars = function() {
  $('.bg-fast-cart').addClass('d-none')
  $('body, html').css('overflow', 'auto')
}

$('.open-fast-cart').click(function () {
  $('.wrapper-fast-cart').removeClass('d-none')
  $('.bg-fast-cart').removeClass('d-none')
  $('body, html').css('overflow', 'hidden')
  setTimeout(function(){
    $('.wrapper-fast-cart').addClass('open')
  }, 100);

})


$('.close-fast-cart').click(function () {
  $('.wrapper-fast-cart').removeClass('open')
  closeFastCars()
})

$('.bg-fast-cart').click(function () {
  $('.wrapper-fast-cart').removeClass('open')
  closeFastCars()

})

$(".filter-mobile .active").prev().addClass('border-bottom-right-radius')
$(".filter-mobile .active").next().addClass('border-top-right-radius')


$("#open-filter").click(function(){
  $(this).addClass("d-none")
  $("#filter-mob").removeClass("d-none")
})


$("#close-filter").click(function(){
  $("#filter-mob").addClass("d-none")
  $("#open-filter").removeClass("d-none")
})

const mediaQuery = window.matchMedia('(max-width: 1000px)')

  $('.open-fast-cart').click(function () {
  $('.wrapper-fast-cart').removeClass('d-none')
  $('.bg-fast-cart').removeClass('d-none')
  $('body, html').css('overflow', 'hidden')
  setTimeout(function(){
    $('.wrapper-fast-cart').addClass('open')
  }, 100);

})

if (mediaQuery.matches) {
  $("#open-modal-login").click(function () {
    $("#mobile-menu").modal("hide")
    $("#modal-login").modal("show")
  })
}
